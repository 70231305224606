<template>
  <div class='card'>
    <div class='container'>


      <div class='row'>
        <h2>Excell Imports</h2>
      </div>
      <div class='row'>
      
      </div>
      <div class='row'>

        <form id="upload_form" enctype="multipart/form-data" multiple method="post">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <input type="file" name="fileUploader" id="fileUploader" class="btn btn-default" multiple>
                </div>
                <div class="col-md-4" style="text-align:right;">
                  <!--onclick​="uploadFiles()"-->
                    <input type="button" id="btnUpload"  class="btn btn-default" value="Upload File" @click='test()' />
                </div>
            </div>
            <div class="row">
              <v-runtime-template :template="template"></v-runtime-template>
              <!--progress :height="20" :value='total' color="blue" /-->
              	<!--v-runtime-template :template="template"></v-runtime-template-->
            
            </div>
        </div>
        </form>   
        <!--form action="http://localhost:57626/api/Import" method="post" enctype="multipart/form-data"-->
        <!--input name="myParam"  custom="CustomVariable" type="hidden" value="i dont do anything" /-->
        <!--<input name="file1" custom1="CustomVariable1" type="file" value="i dont do anything" /-->
        <!--input name="file2" type="file" value="i dont do anything" /-->
        <!--input type="submit" value="OK"/-->
        <!--/form-->
        <br>
        <!--datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered /-->
      </div>

    </div>
  </div>
</template>
<script>
import VRuntimeTemplate from "v-runtime-template";
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import {    mdbProgress   } from '../../mdb/components/Progress';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
import axios from 'axios';
//Vue.use(VRuntimeTemplate);
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Email',
                field: 'Email',
                sort: 'asc',
              },
              {
                label: 'Password',
                field: 'Password',
                sort: 'asc',
              },
              {
                label: 'Salt',
                field: 'Salt',
                sort: 'asc',
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'ContactNumber',
                field: 'ContactNumber',
                sort: 'asc',
              },
              {
                label: 'UserType',
                field: 'UserType',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      total: 10,
      addUsersOpen: false,
      editUsersOpen: false,
      currentUsers: {},
      template:` <progress :height="20" :value='total' color="blue" />`
    };
  },
  components: {

    Datatable,
    mdbProgress,
    VRuntimeTemplate
  },
  
  created: function() {
  
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    test: function() {
    var t =  this.$data.total;
    

    var config = {
          baseURL: this.$store.state.baseUrl,
          headers: {
          'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent){
          //this.total=progressEvent.loaded * 100;
          var percentCompleted = Math.round((progressEvent.loaded * 100)/ progressEvent.total);
          total=progressEvent.loaded * 100;
          }
    }


    var formData = new FormData();
    var imagefile = document.querySelector('#fileUploader');
    formData.append("image", imagefile.files[0]);

    axios.post('UpdateAccessories', formData, config);

    
    /*
    var fileId = i;
                var ajax = new XMLHttpRequest();
                //Progress Listener
                ajax.upload.addEventListener("progress", function (e) {
                    var percent = (e.loaded / e.total) * 100;
                    $("#status_" + fileId).text(Math.round(percent) + "% uploaded, please wait...");
                    $('#progressbar_' + fileId).css("width", percent + "%")
                    $("#notify_" + fileId).text("Uploaded " + (e.loaded / 1048576).toFixed(2) + " MB of " + (e.total / 1048576).toFixed(2) + " MB ");
                }, false);
                //Load Listener
                ajax.addEventListener("load", function (e) {
                    $("#status_" + fileId).text(event.target.responseText);
                    $('#progressbar_' + fileId).css("width", "100%")

                    //Hide cancel button
                    var _cancel = $('#cancel_' + fileId);
                    _cancel.hide();
                }, false);
                //Error Listener
                ajax.addEventListener("error", function (e) {
                    $("#status_" + fileId).text("Upload Failed");
                }, false);
                //Abort Listener
                ajax.addEventListener("abort", function (e) {
                    $("#status_" + fileId).text("Upload Aborted");
                }, false);

                ajax.open("POST", "/api/upload/UploadFiles"); // Your API .net, php

                var uploaderForm = new FormData(); // Create new FormData
                uploaderForm.append("file", file); // append the next file for upload
                ajax.send(uploaderForm);

                //Cancel button
                var _cancel = $('#cancel_' + fileId);
                _cancel.show();

                _cancel.on('click', function () {
                    ajax.abort();
                })
                */


  }

  }
};
</script>
<style scoped>
.UsersThumbnail {
  height: 50px;
}
</style>
